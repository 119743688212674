.tracking-in-expand {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
          animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.bg-pan-left {
  -webkit-animation: bg-pan-left 5s both infinite alternate;
          animation: bg-pan-left 5s both infinite alternate;
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.75s cubic-bezier(0.42, 0, 0.58, 1) both;
          animation: slide-in-left 0.75s cubic-bezier(0.42, 0, 0.58, 1) both;
}

.fade-in {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: fade-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.ani-delay {
  animation-delay: 0.2s;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
    /* -webkit-transform: translate(0, 60px);
    transform: translate(0, 60px); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: translate(0, 0);
    transform: translate(0, 0); */
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    /* -webkit-transform: translate(0, 60px);
    transform: translate(0, 60px); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: translate(0, 0);
    transform: translate(0, 0); */
  }
}

html {
  background: #0D1B1B;
}

.hidden-image {
  visibility: hidden;
  position: relative;
  z-index: 5;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  background: #0D1B1B;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
}

body .loading-screen p {
  margin: 0;
  padding: 0 0 20vh;
  font-size: 2vw;
  font-family: "AmikaExtraBold";
  color: #B4D8D8;
  position: relative;
  z-index: 1000;
}

.loading-dots {
  min-width: 60px;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.main-header {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #0D1B1B;
  z-index: 800;

}

.main-header > div {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.photo-bg {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  position: relative;
  z-index: 800;
}

.main-image img {
  width: height;
  height: 100vh;
}

.title-and-menu {
  position: absolute;
  left: 0;
  top: 3vw;
  display: inline-block;
  z-index: 850;
  background: #0D1B1B;
  padding: 40px;
}

.site-title {
  font-size: 3vw;
  position: relative;
  line-height: 1;
  color: #B4D8D8;
  margin-bottom: 12px;
}

.menu {
  position: relative;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.menu li {
  margin: 0;
  padding: 0;
}

.menu li:first-child {
  padding-top: 3px;
}

.menu a {
  display: block;
  font-size: 1.6vw;
  font-weight: bold;
  padding: 1vh 0;
  font-family: "AmikaExtraBold";
  transition: 0.2s all ease-in-out;
  position: relative;
  color: #B4D8D8;
  line-height: 1;
}

.menu a:hover {
  color: #bb3242;
}

.cato-covers-link span {
  font-size: 1.4vw;
  padding-right: 2px;
  font-weight: bold;
}

.section {
  display: block;
  padding: 2.2vw 3vw;
  position: relative;
  z-index: 900;
  height: auto;
}

.centered-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.section-dark {
  background-color: #0D1B1B;
}

.section-light {
  background-color: #B4D8D8;
}

.section h2 {
  font-size: 3.2vw;
  padding-bottom: 10px;
}

.section h3 {
  font-size: 2vw;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.section h3 + p {
  margin-top: 6px;
}

.section.section-dark h2 {
  color: #B4D8D8;
  border-bottom: 1px solid #B4D8D8;
}

.section.section-light h2 {
  color: #0D1B1B;
  border-bottom: 1px solid #0D1B1B;
}

.section.section-dark h3 {
  color: #B4D8D8;
  border-bottom: 0px solid #B4D8D8;
}

.section.section-light h3 {
  color: #0D1B1B;
  border-bottom: 0px solid #0D1B1B;
}

.section.centered-section h2,
.section.centered-section h3 {
  border-bottom: 0;
  text-align: center;
}

.section.centered-section h2 {
  margin-bottom: 16px;
}

.section.centered-section p {
  text-align: center;
}

.section.centered-section .gallery-caption p {
  font-size: 16px;
}

.section.centered-section p.insta-caption {
  text-align: justify;
  text-justify: inter-word;
}

.text-content {
  width: 63vw;
}

.text-content div.social-container {
  margin: 0;
}

.video-content {
  width: 63vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section p {
  color: #fff;
  font-size: 1.4vw;
  line-height: 1.4;
  text-align: justify;
  text-justify: inter-word;
}

.section.section-light p {
  color: #0D1B1B;
}

.site-footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0D1B1B;
  color: #B4D8D8;
  position: relative;
  z-index: 900;
}

.video-container {
  width: auto;
  height: auto;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.video-container :nth-child(1) {
  order: 2;
}

.video-container :nth-child(2) {
  order: 1;
  transform: scale(90%);
}

.video-container :nth-child(3) {
  order: 3;
  transform: scale(90%);
}

video {
  width: auto;
  height: 70vh;
}

.button-link {
  display: block;
  background: #0D1B1B;
  color: #B4D8D8;
  border-radius: 12px;
  padding: 12px 20px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}

.button-link:hover {
  transform: scale(1.08);
  background: #bb3242;
  color: #B4D8D8;
}

.section-dark a.button-link {
  color: #0D1B1B;
  background: #B4D8D8;
}

.two-columns {
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  justify-content: left;
}

.socials {
  margin-left: 20vw;
}

.a-social {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-bottom: 12px;
}

.a-social a {
  color: #B4D8D8;
}

.section-light .a-social a {
  color: #0D1B1B;
}

.a-social a:last-child {
  padding-left: 10px;
  font-size: 22px;
  font-weight: bold;
}

.section .two-columns p {
  text-align: left;
}

.appearances-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .carousel-status {
  display: none;
}

.appearances-container .carousel .slide img {
  max-width: 40%;
}

.control-dots {
  display: none;
}

.carousel .thumb {
  border: 2px solid #B4D8D8 !important;
  padding: 0 !important;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 2px solid #bb3242 !important;
}

.section.centered-section .text-left p {
  text-align: left;
}

@media (max-width: 1016px) {
  .title-and-menu {
    padding: 16px 24px 20px;
  }

  .site-title {
    font-size: 8vw;
  }

  .photo-bg {
    background-position: bottom center;
    background-size: cover;
  }

  .menu a {
    font-size: 3.6vw;
  }

  .section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .text-content {
    width: 100%;
  }

  .section h2 {
    font-size: 8vw;
    padding-bottom: 2px;
  }

  .text-content > div p:first-child {
    margin-top: 14px;
  }

  .section p {
    font-size: 4.4vw;
  }

  body .loading-screen p {
    font-size: 6vw;
    width: 192px;
  }

  .loading-dots {
    min-width: 30px;
  }

  .video-container {
    flex-direction: column;
  }

  .video-container :nth-child(1) {
    order: 1;
    transform: scale(100%);
  }

  .video-container :nth-child(2) {
    order: 2;
    transform: scale(100%);
  }

  .video-container :nth-child(3) {
    order: 3;
    transform: scale(100%);
  }

  .video-container video {
    margin-bottom: 26px;
  }

  .video-content {
    width: 100%;
    padding-bottom: 16px;
  }

  .centered-section {
    flex-direction: column;
  }

  .video-content h2 {
    font-size: 9vw;
    padding-bottom: 10px;
  }

  .cato-covers-link span {
    font-size: 2.6vw;
  }

  .section h3 {
    font-size: 5vw;
  }

  .text-content div.social-container {
    margin: 0;
  }

  .two-columns {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: left;
  }

  .socials {
    margin-left: 0;
    margin-top: 20px;
  }

  .appearances-container {
    width: 100%;
    display: block;
  }

  .carousel.carousel-slider {
    width: 100vw;
  }

  .carousel {
    width: 100vw;
  }
}
